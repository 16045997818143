<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>Choose your favorite player and follow our guide to get started:</p>
								<p><br /></p>
								<p dir="ltr">
									1.
									<router-link class="text-blue-500" :to="{ name: 'firestickInstallationInstructionsRegularAnd4K' }"> {{ brandInfo.appName }} Guid </router-link>
									<strong>(RECOMMENDED)</strong>
								</p>
								<p>
									2.
									<router-link class="text-blue-500" :to="{ name: 'tivimateInstallationInstructions' }"> Tivimate Guid </router-link>

									<br />3.
									<router-link class="text-blue-500" :to="{ name: 'xCiptvInstallationInstructionMultiScreen' }"> XCiptv Guid </router-link>
								</p>
								<div
									data-identifyelement="188"
									style="
										box-sizing: border-box;
										word-break: normal;
										overflow-wrap: break-word;
										font-size: 13px;
										line-height: 18px;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<br />
								</div>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'fireAmazonFireTvFireStickFireTv4K',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	computed: {
		...mapState({
			brandInfo: (state) => state.brandInfo
		})
	}
};
</script>

<style scoped></style>
